
import { Vue, Component, Watch } from "vue-property-decorator";
import { AlmoxarifadoService, RequisicaoService } from "@/core/services/almoxarifado";
import { Requisicao } from "@/core/models/almoxarifado";
import { mixins } from "vue-class-component";
import ListPage from "@/components/shared/ListPage";
import { EmpreendimentoService } from "@/core/services/cadastros";

@Component
export default class ListaRequisicao extends mixins(Vue, ListPage) {

  item = new Requisicao();
  service = new RequisicaoService();

  loading: boolean = false; 
  sheet:boolean = false;
  dialogCadastro: boolean = false;
  dialogAtender: boolean = false; 
  totalLista: number = 0; 

  public localBuscaRapida: string = "";
  public titulo: string = "Requisição";
  public subTitulo: string = "tipos de requisições cadastrados no Sistema";
  public temBuscaRapida: boolean = true;
  public icone: string = "mdi-cash";
 
  options: any = {
    itemsPerPage: 15,
  };

  filter: {
    id:number,
    executada:boolean,
    almoxarifadoId:number,
    solicitante: string,
    descricao: string,
    data:string,
    empreendimentoId:number
  } = {
    id:0,
    executada: false,
    almoxarifadoId:0,
    solicitante:"",
    descricao: "",
    data: "",
    empreendimentoId:0
  }

  headers: any[] = [
    { text: "", value: "actions", sortable: false },
    { text: "Id", value: "id", type:'number' },
    { text: "Origem", value: "almoxarifado.nome" },
    { text: "Destino", value: "empreendimento.nome" },
    { text: "Solicitante", value: "solicitante" },
    { text: "Título", value: "descricao" },
    { text: "Solicitação", value: "data",type:'text'},
    { text: "Executada", value: "executada", type:'text' },
  ]; 
  
  @Watch("options", { deep: true })
  Atualizar() {

      let paramTemp = '';

        if (this.filter.id > 0){
          if (paramTemp) { paramTemp += ' and '; }
          paramTemp += `id eq ${this.filter.id}`;
        }

        if(this.filter.executada){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `executada eq ${this.filter.executada}`;
        } 

        if(this.filter.almoxarifadoId){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `almoxarifadoId eq ${this.filter.almoxarifadoId}`;
        }    

        if(this.filter.solicitante){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `solicitante eq '${this.filter.solicitante}'`;
        } 

        if(this.filter.descricao){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `descricao eq '${this.filter.descricao}'`;
        } 

        if(this.filter.data){
          if(paramTemp){paramTemp += ' and '; }
          paramTemp += `data eq ${this.filter.data}`; 
        } 

        if (this.filter.empreendimentoId){
          if(paramTemp) { paramTemp += ' and '; }
          paramTemp += `empreendimentoId eq ${this.filter.empreendimentoId}`;
        }


    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.Listar(page, itemsPerPage, sortBy, sortDesc, this.search, this.headers, paramTemp, 'Almoxarifado, Pedido, Itens, Empreendimento').then(
      res => {
        this.lista = res.data.items;
        this.totalLista = res.data.count;
      },
       (err) => {
          if (!err.response) {
            this.$swal("Aviso", "Não foi possível acessar a API", "error");
          } else if (err.response.status == 403) {
            this.$swal("Aviso", err.response.data.message, "warning" );
          } else {
            this.$swal("Aviso",  err.response.data, err.response.status == 400 ? "warning" : "error");
          }
        })
    .finally(() => (this.loading = false));
  }

  Novo() {
    this.item = new Requisicao();
    this.dialogCadastro = true;
  }
  Editar(item: Requisicao){
    this.service.ObterPorId(item.id, 'Almoxarifado.Itens, Itens.Produto, Itens.Etapa')
      .then(   
        res => {
          this.item = res.data;
          this.dialogCadastro = true;
    }); 
  }   
   Excluir(item: Requisicao) {
    this.$swal({
      title: "Atenção!",
      text: "Tem certeza que deseja excluir o registro atual?",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showCloseButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.service.Excluir(item.id).then(
          (res) => {
            if (res.status == 200) {
              return res; 
            }
          },
          (err) => this.$swal("Aviso", err.response.data, "error")
        );
      },
      // @ts-ignore: Unreachable code error
      allowOutsideClick: () => !this.$swal.isLoading(),
    }).then((result:any) => {
      if (result.value) {
        this.$swal(
          "Aviso",
          result.value.data,
          result.value.status == 200 ? "success" : "warning"
        );
        this.Atualizar();
      }
    });
  } 

  Consultar() {
    const { page, itemsPerPage, sortBy, sortDesc, search, columns } = this.options;
    this.loading = true;

    this.service.ListarComFiltro(1,itemsPerPage,sortBy,sortDesc,search,columns, this.filter, 'Almoxarifado, Pedido, Itens, Empreendimento').then(
    (res) => { 
      this.lista = res.data.items
      this.totalLista = res.data.count;
      this.loading = false; 
    })
    this.sheet = false;
  } 
  
  Atender(item: Requisicao) {
    this.service.ObterPorId(item.id, 'Almoxarifado, Almoxarifado.Itens,Itens.Produto.AlmoxarifadoItens, Itens.Produto, Itens.Etapa, Empreendimento')
      .then(  
        res => {
        this.item = res.data;
        this.dialogAtender = true;
    }); 
  }
  almoxarifados = [];
  empreendimentos =[];

  mounted(){
    this.Atualizar();

    new EmpreendimentoService().ListarTudo().then(
      res => {this.empreendimentos = res.data.items}
    )
    
    let almoxarifadoService = new AlmoxarifadoService;
    almoxarifadoService.ListarTudo().then(
      res => { 
        this.almoxarifados = res.data.items
      })
  }
}
